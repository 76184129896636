import {ApiRequest} from "~/utils/api-request";
import cookie from "~/utils/cookie";

let api_url = (cookie.authToken.value()) ? 'lots/counts/for-my' : 'lots/counts';
let api_url_compact = (cookie.authToken.value()) ? 'lots/counts/for-my/current' : 'lots/counts';

let apiRequest = new ApiRequest('get', api_url);
let apiRequestCompact = new ApiRequest('get', api_url_compact);

apiRequest.onSuccess((data) => {
    // console.log(data);
});
apiRequestCompact.onSuccess((data) => {
    // console.log(data);
});

apiRequest.onError((error) => {
    // errors

});
apiRequestCompact.onError((error) => {
    // errors

});

const getLotsCounts = async() => {
    return apiRequest.request(true);
}

const lotsCountsRefresh = async() => {
    apiRequest = new ApiRequest('get', api_url);
}

const getLotsCountsCompact = async() => {
    return apiRequestCompact.request(true);
}
const lotsCountsRefreshCompact = async() => {
    apiRequestCompact = new ApiRequest('get', api_url_compact);
}

export {getLotsCounts, lotsCountsRefresh, getLotsCountsCompact, lotsCountsRefreshCompact};