<template>
  <div class="sidemenu">
    <div class="sidemenu-top">
      <ul class="sidemenu-list">
        <li class="all">
          <nuxt-link to="/" @click="toggleMenu">
            <img :src="layoutIcons.iconAll" alt="" />
            Все торги
          </nuxt-link>
        </li>
      </ul>
      <ul class="sidemenu-list" v-if="user.init && user.isConfirmed">
        <li v-for="(item, key) in menuTop" :key="key">
          <nuxt-link :to="item.link" @click="toggleMenu">
            <img :src="item.icon" alt="" />
            {{item.name}}
            <span class="total" v-if="item.value.total > 0">({{item.value.total}})</span>
            <span class="fail" v-if="item.value.fail > 0">{{item.value.fail}}</span>
            <span class="success" v-if="item.value.success > 0">{{item.value.success}}</span>
          </nuxt-link>
        </li>
      </ul>
    </div>
    <div class="sidemenu-bottom">
      <ul class="sidemenu-list --special">
        <li>
          <a href="https://flexirent.ru/" target="_blank">
            <img :src="layoutIcons.iconLink" alt="Аренда авто" />
            Аренда авто
          </a>
        </li>
        <li>
          <a href="https://expomobility.com/" target="_blank">
            <img :src="layoutIcons.iconLink" alt="ExpoMobility" />
            ExpoMobility
          </a>
        </li>
      </ul>
      <ul class="sidemenu-list">
        <li v-for="item in menuBottom">
          <nuxt-link :to="item.link" @click="toggleMenu">
            <img :src="item.icon" alt="" />
            {{item.name}}
          </nuxt-link>
        </li>
      </ul>
      <ul class="sidemenu-list --technical">
        <li>
          <a :href="`${config().apiUrl}/files/polzovatelskoe-soglashenie`" target="_blank" @click="toggleMenu">
            <img :src="layoutIcons.iconDoc" alt="" />
            Пользовательское <br>соглашение
          </a>
        </li>
        <li>
          <a :href="`${config().apiUrl}/files/politika-v-otnoshenii-obrabotki-personalnyh-dannyh`" target="_blank" @click="toggleMenu">
            <img :src="layoutIcons.iconDoc" alt="" />
            Политика обработки <br>персональных данных
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import layoutIcons from "~/utils/layout-icons";

  import {loadUserInfo, user} from "~/utils/user";
  import {getLotsCounts, lotsCountsRefresh, getLotsCountsCompact, lotsCountsRefreshCompact} from "~/utils/api/lots-counts";
  import {echoEvents} from "~/utils/echo";
  import config from "~/utils/config";

  const toggleMenu = () => {
    document.body.classList.remove('menu-expanded');
  }

  const flag = ref(false);

  let counts = ref({
    all: 0,
    active: 0,
    my: 0,
    current: 0,
    favorites: 0,
    waiting: 0,
    deal: 0,
    finished_deal: 0,
    archive: 0,
  });

  let menuTop = ref({
    my: {
      link: '/my',
      icon: layoutIcons.iconHome,
      name: 'Мои торги',
      value: {
        total: 0,
        fail: 0,
        success: 0
      }
    },
    favorites: {
      link: '/favorites',
      icon: layoutIcons.iconHeart,
      name: 'Избранное',
      value: {
        total: 0,
        fail: 0,
        success: 0
      }
    },
    current: {
      link: '/current',
      icon: layoutIcons.iconClock,
      name: 'Текущие торги',
      value: {
        total: 0,
        fail: 0,
        success: 0
      }
    },
    waiting: {
      link: '/waiting',
      icon: layoutIcons.iconWait,
      name: 'В обработке',
      value: {
        total: 0,
        fail: 0,
        success: 0
      }
    },
    deal: {
      link: '/deals',
      icon: layoutIcons.iconList,
      name: 'Сделки',
      value: {
        total: 0,
        fail: 0,
        success: 0
      }
    },
    finished_deal: {
      link: '/finished',
      icon: layoutIcons.iconCheck,
      name: 'Завершенные сделки',
      value: {
        total: 0,
        fail: 0,
        success: 0
      }
    },
    archive: {
      link: '/archive',
      icon: layoutIcons.iconCalendar,
      name: 'Архив ставок',
      value: {
        total: 0,
        fail: 0,
        success: 0
      }
    },
  });

  let menuBottom = ref([
    {
      link: '/help',
      icon: layoutIcons.iconHelp,
      name: 'Помощь',
    },
    {
      link: '/contacts',
      icon: layoutIcons.iconContact,
      name: 'Контакты',
    },
    {
      link: '/about',
      icon: layoutIcons.iconInfo,
      name: 'О компании',
    },
  ]);

  const getCounts = async() => {

    let data = await getLotsCounts();

    menuTop.value.current.value.total = data.current.all;
    menuTop.value.current.value.fail = data.current.userNeedUpPrice;
    menuTop.value.current.value.success = data.current.userIsLeader;
    menuTop.value.my.value.total = data.my;
    menuTop.value.favorites.value.total = data.favorites;
    menuTop.value.waiting.value.total = data.waiting;
    menuTop.value.deal.value.total = data.deal;
    menuTop.value.finished_deal.value.total = data.finished_deal;
    menuTop.value.archive.value.total = data.archive;

    // console.log(data);
  }

  let simenuLastSendedSecond = null;

  const getCountsCompact = async() => {

    let data = await getLotsCountsCompact();

    menuTop.value.current.value.total = data.current.all;
    menuTop.value.current.value.fail = data.current.userNeedUpPrice;
    menuTop.value.current.value.success = data.current.userIsLeader;
    menuTop.value.my.value.total = data.my;

    // console.log(data);
  }

  onMounted(async () => {

      await loadUserInfo();

      if (user.init && user.isConfirmed) {
        await getCounts();

        echoEvents["lot-favorite-change-event"]['sidemenu'] = async (message) => {
          await lotsCountsRefresh();
          await getCounts();
        };

        echoEvents["bid-added-event"]['sidemenu'] = async (message) => {

          if (simenuLastSendedSecond === null) {
            simenuLastSendedSecond = (Date.now() / 1000);
            await lotsCountsRefreshCompact();
            await getCountsCompact();

            return;
          }

          let t = (Date.now() / 1000) - simenuLastSendedSecond;

          if (t > 5) {
            await lotsCountsRefreshCompact();
            await getCountsCompact();
          }

        };

        echoEvents["lot-update-status-event"]['sidemenu'] = async (message) => {
          await lotsCountsRefresh();
          await getCounts();
        };

      }

  });
</script>

<style scoped>
  .sidemenu{
    min-height: 100%;
    border-radius: 15px;
    background: #FFF;
    display: flex;
    padding: 1.6rem;
    flex-direction: column;
    justify-content: space-between;
    width: 286px;
  }
  .sidemenu-list li{
    margin-top: 16px;
    font-size: 16px;
    line-height: 20px;
  }
  .sidemenu-list.--technical li{
    font-size: 12px;
    line-height: 14px;
  }
  .sidemenu-list li.all{
    font-weight: 600;
    margin-bottom: 32px;
  }
  .sidemenu-list li:first-child{
    margin-top: 0
  }
  .sidemenu-list li a{
    display: flex;
    align-items: center;
  }
  .sidemenu-list li .router-link-exact-active{
    font-weight: 700;
  }
  .sidemenu-list.--special li a{
    color: #FC6337;
  }
  .sidemenu-list li img{
    width: 17px;
    height: 17px;
    margin-right: 8px;
    margin-bottom: 2px;
    display: inline-block;
  }
  .sidemenu-top .sidemenu-list li img{
    margin-right: 5px;
  }
  .sidemenu-list li.all img{
    margin-right: 10px;
  }
  .sidemenu-bottom .sidemenu-list{
    margin-top: 32px;
  }
  .sidemenu-list li span{
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
    color: rgba(9, 15, 17, 0.5);
  }
  .sidemenu-list li .total{
    margin-right: 2px;
  }
  .sidemenu-list li .fail,
  .sidemenu-list li .success{
    height: 20px;
    width: 20px;
    text-align: center;
    font-weight: 500;
    border-radius: 50%;
  }
  .sidemenu-list li .fail{
    background: #E6E7E7;
  }
  .sidemenu-list li .success{
    background: #14BEAA;
    color: #fff;
  }
</style>
